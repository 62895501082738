import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Logo from './LogoWithText';
import './Header.css';

function Header() {
  const wordsRef = useRef([]);
  const headerRef = useRef(null);

  useEffect(() => {
    // Ensure GSAP is available
    if (!gsap) {
      console.error('GSAP is not properly imported');
      return;
    }

    // Create a GSAP timeline for sequencing animations
    const tl = gsap.timeline();

    // Intro Animation: Blurred fade-in
    tl.fromTo(
      wordsRef.current,
      {
        opacity: 0,
        filter: "blur(20px)",
        y: 100,
      },
      {
        opacity: 1,
        filter: "blur(0px)",
        y: 0,
        duration: 2.5,
        ease: "power1.out",
        stagger: 0.3,
      }
    );

    // Scroll-triggered drop animation
    const handleScroll = () => {
      if (wordsRef.current && wordsRef.current.length > 0) {
        gsap.to(wordsRef.current, {
          y: 500,
          opacity: 0,
          duration: 1.5,
          ease: "power2.in",
          stagger: 0.4,
          onComplete: () => {
            window.removeEventListener('scroll', handleScroll);
          }
        });
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener('scroll', handleScroll);
      tl.kill(); // Ensure timeline is killed to prevent memory leaks
    };
  }, []);

  // Split text into words and create refs
  const animatedWords = "Effective Communication Design...".split(" ").map((word, index) => (
    <div 
      key={index} 
      className="word"
      ref={el => {
        if (wordsRef.current) {
          wordsRef.current[index] = el;
        }
      }}
    >
      {word}
    </div>
  ));

  return (
    <header 
      ref={headerRef} 
      className="header"
    >
      <div className="logo-container">
        <Logo />
      </div>
      
      <span className="vertical-text">KARNOWSKI DESIGN</span>
      
      <div className="heading-wrapper">
        <h1>{animatedWords}</h1>
      </div>
      
      <div className="intro-wrapper">
        <p>
          Effective communication is key to reaching your audience and delivering a message that resonates.
          With over 20 years of experience in communication design, I specialise in crafting smart,
          visually compelling, and impactful design solutions across digital media and print.
        </p>
      </div>
    </header>
  );
}

export default Header;